<template>
  <color-text-btn
    type="info"
    size="mini"
    class="filter-item line"
    v-if="canCheckShow && data.sizeCheckList"
    @click="check"
  >
    <span>效果图校验</span>
  </color-text-btn>
  <color-text-btn
    v-else-if="canCheckShow && data.sizeCheckList === null"
    type="warning"
    size="mini"
    class="line"
  >请先校验参考尺码</color-text-btn>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    isLack: {
      type: Boolean
    }
  },
  computed: {
    canCheckShow({ data, isLack }) {
      return Object.keys(data.referSize || {}).length > 0 && !isLack
    }
  },
  methods: {
    check() {
      const hasShowImage = this.data.styleList.some((item) => {
        return item.showImageGroupList.some((item2) => {
          return Object.keys(item2.showImageGroupItemList).length
        })
      })
      if (!hasShowImage) {
        this.$message.error('请先上传效果图')
        return
      }
      const id = this.data.referSize.id
      const protoId = this.data.id
      this.$router.push(`/product/maintain/checkProShow?id=${id}&protoId=${protoId}`)
    }
  }
}
</script>

<style></style>
